<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <v-progress-circular
        v-if="loading"
        :size="100"
        :width="4"
        color="fmq_black"
        indeterminate
        class="d-flex mx-auto py-8 mt-8"
      ></v-progress-circular>

      <div v-else>
        <div class="pa-4">
          <div class="d-flex justify-space-between align-center mb-4">
            <v-btn small depressed color="fmq_gray" dark outlined to="/">
              <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
            >
          </div>
          <SolicitacaoStepper
            @send="editar"
            :solicitacao="solicitacao"
            :loadingBtn="loadingBtn"
            :listMedicoSolicitante="listMedicoSolicitante"
            :listProdutos="listProdutos"
            :listAssuntos="listAssuntos"
            :listRepresentantes="listRepresentantes"
          />
        </div>
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Solicitação editata com sucesso"
      @close="goToEspecialidade"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { editarSolicitacao, exibirSolicitacao } from "@/services/solicitacoes";
import SolicitacaoStepper from "@/components/solicitacoes/SolicitacaoStepper.vue";
import { listarMedicoSolicitante } from "@/services/medico-solicitante";
import { listarProdutos } from "@/services/produtos";
import { listarRepresentantes } from "@/services/user";
export default {
  name: "SolicitacoesEditar",
  components: { SolicitacaoStepper },
  data: () => ({
    breadcrumbs: [
      {
        text: "Lista de Solicitações",
        disabled: false,
        to: "/",
      },
      {
        text: "Editar Solicitação",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    listMedicoSolicitante: [],
    listProdutos: [],
    listAssuntos: [
      { name: "Comparativo", value: "Comparativo" },
      { name: "Comprovação de segurança", value: "Comprovação de segurança" },
      {
        name: "Comprovação de eficácia e segurança",
        value: "Comprovação de eficácia e segurança",
      },
      { name: "Mecanismo de ação", value: "Mecanismo de ação" },
      {
        name: "Material de marketing (Separata ou Monografia)",
        value: "Material de marketing (Separata ou Monografia)",
      },
      { name: "Modo de uso/posologia", value: "Modo de uso/posologia" },
      { name: "Composição do produto", value: "Composição do produto" },
      { name: "Referência bibliográfica", value: "Referência bibliográfica" },
      { name: "Padronização", value: "Padronização" },
      { name: "Outros", value: "Outros" },
    ],
    listRepresentantes: [],
    solicitacao: {},
    loading: false,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getMedicosSolicitantes();
    this.getProdutos();
    this.getRepresentantes();
    this.exibir().then(() => {
      if (
        this.checkStatus(
          ["Em aprovação", "Consulta interna", "Finalizado"],
          this.solicitacao.status
        ) ||
        this.mesaId === 2
      ) {
        this.$router.push({ name: "Solicitacoes" });
      }
    });
  },
  methods: {
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirSolicitacao(this.$route.params.id);
        this.solicitacao = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async editar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarSolicitacao(this.$route.params.id, event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goToEspecialidade() {
      this.sucess = false;
      this.$router.push({ name: "Solicitacoes" });
    },
    async getMedicosSolicitantes() {
      const resp = await listarMedicoSolicitante();
      this.listMedicoSolicitante = resp.data;
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getRepresentantes() {
      const resp = await listarRepresentantes();
      this.listRepresentantes = resp.data;
    },
    checkStatus(status, status_check) {
      return status.indexOf(status_check) >= 0;
    },
  },
  computed: {
    mesaId() {
      return this.$store.state.user.data.funcionario.mesaId;
    },
  },
};
</script>

<style></style>
