<template>
  <v-card elevation="1">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 1" step="1" color="fqm_blue">
          Informações do Médico
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2" color="fqm_blue">
          Dados de Envio
        </v-stepper-step>

        <v-divider></v-divider>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form ref="formMedico" v-model="valid" lazy-validation>
            <v-row class="pt-2">
              <v-col>
                <v-autocomplete
                  v-model="medicoSolicitante"
                  :items="listMedicoSolicitante"
                  hide-no-data
                  hide-selected
                  item-text="nome"
                  item-value="id"
                  label="Buscar médico"
                  placeholder="Selecione um(a) médico(a) solicitante"
                  outlined
                  dense
                  :clearable="true"
                  required
                  :rules="rules.selectRules"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>

          <div class="dados-solicitante">
            <div class="header">Solicitante</div>
            <ul v-if="medicoSolicitante">
              <li>
                CRM: <span>{{ medicoSolicitante.crm }}</span>
              </li>
              <li>
                Especialidades:
                <v-chip
                  class="ma-1"
                  small
                  v-for="especialidade in medicoSolicitante.especialidades"
                  :key="especialidade.nome"
                >
                  {{ especialidade.nome }}</v-chip
                >
              </li>
              <li>
                E-mail: <span>{{ medicoSolicitante.email }}</span>
              </li>
            </ul>
          </div>

          <div
            class="d-flex justify-end align-center my-4"
            v-if="medicoSolicitante"
          >
            <v-btn color="fmq_gray" dark @click="nextStep" depressed>
              Próximo passo <v-icon small> mdi-arrow-right </v-icon></v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form ref="formEnvio" v-model="valid" lazy-validation>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-autocomplete
                  v-model="formData.produtosId"
                  :items="listProdutos"
                  hide-no-data
                  hide-selected
                  item-text="nome"
                  item-value="id"
                  label="Nome do Produto"
                  placeholder="Selecione o produto"
                  outlined
                  dense
                  :clearable="true"
                  required
                  :rules="rules.selectRules"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="formData.assunto"
                  :items="listAssuntos"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="value"
                  label="Motivo da solicitação"
                  placeholder="Selecione o motivo da solicitação"
                  outlined
                  dense
                  :clearable="true"
                  required
                  :rules="rules.selectRules"
                ></v-autocomplete>
                <v-text-field
                  v-if="formData.assunto === 'Outros'"
                  outlined
                  label="Outro(s) motivo(s)"
                  placeholder="Outro(s) motivo(s)"
                  v-model="outro"
                  :clearable="true"
                  dense
                  required
                  :rules="rules.genericRules"
                ></v-text-field>
                <v-textarea
                  outlined
                  label="Relato"
                  placeholder="Relato ou justificativa"
                  v-model="formData.conteudo"
                  :clearable="true"
                  rows="3"
                  required
                  :rules="rules.genericRules"
                ></v-textarea>
                <v-radio-group v-model="modoEnvio" class="mt-0">
                  <template v-slot:label>
                    <div>Modo de Envio:</div>
                  </template>
                  <v-radio label="E-mail" value="email"></v-radio>
                  <v-radio label="Correio" value="correios"></v-radio>
                </v-radio-group>
                <v-autocomplete
                  v-model="formData.representanteId"
                  :items="listRepresentantes"
                  hide-no-data
                  hide-selected
                  item-text="nome"
                  item-value="id"
                  label="Nome do Representante"
                  placeholder="Selecione o representante"
                  outlined
                  dense
                  :clearable="true"
                  required
                  :rules="rules.selectRules"
                  :disabled="$store.state.user.data.tipo === 'Representante'"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
          <div class="d-flex align-center justify-space-between my-4">
            <v-btn color="fmq_gray" dark depressed outlined @click="step = 1">
              <v-icon small> mdi-arrow-left </v-icon>Etapa Anterior</v-btn
            >
            <v-btn
              color="fmq_gray"
              dark
              depressed
              @click="send"
              :loading="loadingBtn"
              >{{ isEdit ? "Editar" : "Criar" }}</v-btn
            >
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "ProdutoFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    listMedicoSolicitante: {
      type: Array,
      required: true,
    },
    listProdutos: {
      type: Array,
      required: true,
    },
    listAssuntos: {
      type: Array,
      required: true,
    },
    listRepresentantes: {
      type: Array,
      required: true,
    },
    solicitacao: {
      type: Object,
      default: () => {},
    },
    representanteId: {
      type: Number,
    }
  },
  data: () => ({
    formData: {
      produtosId: null,
      assunto: null,
      conteudo: null,
      envioCorreios: false,
      envioEmail: false,
      representanteId: null,
    },
    medicoSolicitante: null,
    outro: null,
    modoEnvio: "email",
    rules: rules,
    valid: true,
    isEdit: false,
    step: 1,
  }),
  created() {
    if (this.$route.name === "SolicitacoesEditar") {
      const medico = this.listMedicoSolicitante.filter(
        (item) => this.solicitacao.medicoSolicitante.id === item.id
      );
      this.medicoSolicitante = medico[0]
      this.formData.produtosId = this.solicitacao.produtosId;
      this.formData.assunto = this.solicitacao.assunto;
      this.formData.conteudo = this.solicitacao.conteudo;
      this.formData.envioCorreios =
        this.solicitacao.modoEnvio === "correios" ? true : false;
      this.formData.envioEmail =
        this.solicitacao.modoEnvio === "email" ? true : false;
      this.formData.representanteId = this.solicitacao.representanteId;
      this.isEdit = true;
    }
  },
  methods: {
    validateMedico() {
      this.$refs.formMedico.validate();
    },
    validadeEnvio() {
      this.$refs.formEnvio.validate();
    },
    send() {
      if (this.formEnvio)
        this.$emit("send", {
          ...this.formData,
          envioCorreios: this.modoEnvio === "correios" ? true : false,
          envioEmail: this.modoEnvio === "email" ? true : false,
          medicoSolicitante: {
            id: this.medicoSolicitante.id,
            crm: this.medicoSolicitante.crm,
            nome: this.medicoSolicitante.nome,
            email: this.medicoSolicitante.email,
            especialidades: this.medicoSolicitante.especialidades.map(
              (item) => item.id
            ),
          },
        });
    },
    nextStep() {
      if (this.formMedico) this.step = 2;
    },
  },
  computed: {
    formMedico() {
      return this.$refs.formMedico.validate();
    },
    formEnvio() {
      return this.$refs.formEnvio.validate();
    },
  },
  watch: {
    representanteId: function(newValue) {
      if(this.$store.state.user.data.tipo === "Representante") {
        this.formData.representanteId = newValue
      }
    }
  }
};
</script>

<style scoped>
.v-stepper__header {
  box-shadow: none;
}
.dados-solicitante {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.dados-solicitante .header {
  padding: 16px;
  background: #f8f9fa;
  border-bottom: 1px solid #17a2b8;
  font-weight: bold;
}
.dados-solicitante ul {
  list-style: none;
  padding: 16px;
}
.dados-solicitante ul li {
  margin-bottom: 8px;
}
</style>
